import React from "react";

const EditableStringSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6782 17.0522H1.9957V4.36973H8.33695V3.65723H1.2832V17.7647H15.3907V9.85598H14.6782V17.0522Z"
        fill="#ff3300"
      />
      <path
        d="M5.36789 10.9724L4.86914 14.0836L7.98039 13.5849L8.28914 13.2524L17.7416 3.82361L15.1291 1.23486L5.70039 10.6636L5.36789 10.9724ZM6.00914 11.4711L7.45789 12.9436L5.70039 13.2286L6.00914 11.4711ZM16.7204 3.82361L15.7941 4.77361L14.2266 3.18236L15.1766 2.25611L16.7204 3.82361ZM13.7041 3.68111L15.2954 5.27236L8.02789 12.5161L6.46039 10.9249L13.7041 3.68111Z"
        fill="#ff3300"
      />
    </svg>
  );
};

export default EditableStringSvg;
