import React from "react";

const ShareIconSVG = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1458 20H3.22917C2.37274 20 1.55139 19.6598 0.945801 19.0542C0.340215 18.4486 0 17.6273 0 16.7708V3.85417C0 2.99774 0.340215 2.17639 0.945801 1.5708C1.55139 0.965215 2.37274 0.625 3.22917 0.625H7.53472C7.8202 0.625 8.09398 0.738405 8.29584 0.940267C8.49771 1.14213 8.61111 1.41591 8.61111 1.70139C8.61111 1.98686 8.49771 2.26065 8.29584 2.46251C8.09398 2.66437 7.8202 2.77778 7.53472 2.77778H3.22917C2.94369 2.77778 2.66991 2.89118 2.46804 3.09304C2.26618 3.29491 2.15278 3.56869 2.15278 3.85417V16.7708C2.15278 17.0563 2.26618 17.3301 2.46804 17.532C2.66991 17.7338 2.94369 17.8472 3.22917 17.8472H16.1458C16.4313 17.8472 16.7051 17.7338 16.907 17.532C17.1088 17.3301 17.2222 17.0563 17.2222 16.7708V12.4653C17.2222 12.1798 17.3356 11.906 17.5375 11.7042C17.7393 11.5023 18.0131 11.3889 18.2986 11.3889C18.5841 11.3889 18.8579 11.5023 19.0597 11.7042C19.2616 11.906 19.375 12.1798 19.375 12.4653V16.7708C19.375 17.6273 19.0348 18.4486 18.4292 19.0542C17.8236 19.6598 17.0023 20 16.1458 20Z"
        fill="#ff3300"
      />
      <path
        d="M19.3747 1.75547V7.13742C19.3737 7.34993 19.3097 7.55736 19.191 7.73359C19.0722 7.90982 18.9039 8.04695 18.7074 8.1277C18.5796 8.18796 18.4395 8.21745 18.2983 8.21381C18.1567 8.21463 18.0163 8.18748 17.8851 8.13391C17.754 8.08034 17.6347 8.00142 17.5341 7.90166L15.5536 6.00721L10.3976 11.1631C10.2976 11.264 10.1785 11.3441 10.0474 11.3987C9.9162 11.4534 9.77551 11.4815 9.63341 11.4815C9.49132 11.4815 9.35063 11.4534 9.21946 11.3987C9.08829 11.3441 8.96924 11.264 8.86918 11.1631C8.76829 11.0631 8.68821 10.944 8.63356 10.8128C8.57892 10.6817 8.55078 10.541 8.55078 10.3989C8.55078 10.2568 8.57892 10.1161 8.63356 9.98492C8.68821 9.85376 8.76829 9.73471 8.86918 9.63464L13.9928 4.44645L12.0983 2.51971C11.9435 2.37198 11.8363 2.18145 11.7903 1.97247C11.7443 1.76349 11.7616 1.54554 11.84 1.34645C11.9259 1.13572 12.0763 0.957609 12.2697 0.837706C12.4631 0.717803 12.6895 0.662271 12.9164 0.679086H18.2983C18.3874 0.663612 18.4784 0.663612 18.5674 0.679086C18.6172 0.669496 18.6684 0.669496 18.7181 0.679086C18.8473 0.729963 18.9646 0.806865 19.0627 0.905008C19.1608 1.00315 19.2377 1.12044 19.2886 1.24957C19.2982 1.29935 19.2982 1.35049 19.2886 1.40027C19.3448 1.51025 19.3743 1.63195 19.3747 1.75547Z"
        fill="#ff3300"
      />
    </svg>
  );
};

export default ShareIconSVG;
